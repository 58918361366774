import './App.css';
import ConnectToPhantom from "../src/Components/ConnectToPhantom.tsx";

function App() {
  // var id = '1PXA-tOul7WX3Q68xkeR5QZjH-y6ZaRHVqfC8RWycJN4';
  // var gid = '0';
  // var url = 'https://docs.google.com/spreadsheets/d/'+id+'/gviz/tq?tqx=out:json&tq&gid='+gid;
  // fetch(url)
  // .then(response => response.text())
  // .then(data => document.getElementById("json").innerHTML=data
  // );
  return (
    <div>
      {/* <h1 id='json'></h1> */}
      <header className="header">
      <nav className="nav container">
        <div className="logo">
          <a href="small logo.png">
            <img
              className="img-fluid"
              src="/assets/images/small logo.png"
              alt="hotdogz"
            />
          </a>
        </div>

        <div className="toggle-menu" onClick={navChange}>
          <i className="fa fa-bars"></i>
        </div>

        <ul className="nav-list">
          <li className="nav-item"><a className="nav-link" href="#mint">Mint</a></li>
          <li className="nav-item"><a className="nav-link" href="#about">About</a></li>
          <li className="nav-item"><a className="nav-link" href="#roadmap">Roadmap</a></li>
          <li className="nav-item"><a className="nav-link" href="#faq">Faq</a></li>
          <li className="nav-item"><a className="nav-link" href="#team">Team</a></li>
          <li className="nav-item">
      <ConnectToPhantom />
          </li>
        </ul>
      </nav>
    </header>

    <main>
      <section className="hero">
        <img src="/assets/images/hero.png" alt="" />
        <div className="container">
          <div className="content">
            <h2>UNIQUELY GENERATED FULLY LOADED ART</h2>
            <p className="description">on the Solana Ecosystem</p>

            <ul className="icons-list">
              <li>
                <a target="_blank" href="https://discord.gg/Gh9GSdFPe7" rel="noreferrer">
                  <i className="fab fa-discord"></i>
                </a>
              </li>
              <li>
                <a target="_blank" href="https://twitter.com/HotDogzVerse" rel="noreferrer">
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a target="_blank" href="https://www.instagram.com/hotdogz_nft" rel="noreferrer">
                  <i class="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="mint" id="mint">
        <div className="container">
          <div className="content">
            <h2><img src="./assets/images/about.png" alt="hotdogz" /></h2>
            <p className="description">
              MINT A HOT DOGZ NFT BY CONNECTING YOUR PHANTOM WALLET
            </p>
          </div>
          <div className="flex-row">
            <div className="col-md-6">
              <img
                className="img-fluid"
                src="./assets/images/about-33.png"
                alt="hotdogz"
              />
            </div>
            <div className="col-md-6 img">
              <div className="box">
                <h3><img src="./assets/images/about-2.png" alt="hotdogz"/></h3>
                <div className="text"></div>
                <div className="shape"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about" id="about">
        <div className="container">
          <div className="flex-row">
            <div className="col-md-6">
              <div className="content">
                <p className="heading-top">Who are the </p>
                <h2><img src="./assets/images/about-1.png" alt="hotdogz" /></h2>
                <p className="description">
                  We are cute and adorable Hot Dogz, having fun in the
                  Solana space. You might think of us just as a NFT, but let me
                  tell you - we are much more.
                </p>
                <p className="description">
                  We are building our own place, where you can adopt us, help
                  real life shelter dogs that need you, or even purchase some
                  pieces from our unique clothing and accessories line.
                </p>
                <p className="description">
                  This is not the place to take yourself too seriously. Hang
                  out, meet some friends and who knows maybe you will adopt
                  puppies in the future too. And what about the idea to match
                  your styling with your pet?
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <img className="img-fluid" src="/assets/images/about10.png" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="roadmap" id="roadmap">
        <div className="container">
        <div
            class="container"
               style={{
                padding: "0",
               margin: "0",
             }}
            >
          <img
              className="img-fluid"
               src="/assets/images/HD roadmap II 02.jpg"
              alt=""
              style={{
      height: "100%",
      width: "100%",
      objectFit: "cover"
    }}
            />
          </div>
        </div>
      </section>
      <section className="faq-section" id="faq">
        <div className="container">
          <h2><img src="./assets/images/faq.png" alt="hotdogz" /></h2>
          <div className="questions">
            <ul className="single-questions">
              <li className="faq">What is the Hot Dogz mission?</li>
              <p className="answer">
                -We aim to provide a platform which will allow all relevant people in pet life to collaborate more easily 
                through the many valuable services offered to them by Hot Dogz. From the very beginning our first goal is
                to build a friendly, safe and generous community that has a common love for animals and improve the lives
                of dogs and other animals.
              </p>
            </ul>
            <ul className="single-questions">
              <li className="faq">What is the Hot Dogz vision?</li>
              <p className="answer">
                -Our vision is simple: a world with more benefits for dogs, their owners, and veterinary services available
                 through our products. Hot Dogz will lead the way into a secure future of decentralization, digitalization,
                 through the protection of your pet's identity, health, and husbandry using cutting-edge blockchain technology.
              </p>
            </ul>
            <ul className="single-questions">
              <li className="faq">Who represents the core team?</li>
              <p className="answer">
              -Take a look at the core team in the team section just below. Also,
               we have a detailed bio for our members in our Discord server.
              </p>
            </ul>
            <ul className="single-questions">
              <li className="faq">When will Hot Dogz be launched?</li>
              <p className="answer">-TBA</p>
            </ul>
            <ul className="single-questions">
              <li className="faq">What is the supply of the collection?</li>
              <p className="answer">-TBA.</p>
            </ul>
            <ul className="single-questions">
              <li className="faq">How much will it cost to mint?</li>
              <p className="answer">
                -TBA.</p>
            </ul>
            <ul className="single-questions">
              <li className="faq">Where will Hot Dogz be listed?</li>
              <p className="answer">
                -The application will be sent to Magic Eden, and we expect that we
                will be pre-approved and listed right after mint.
              </p>
            </ul>
            <ul className="single-questions">
              <li className="faq">Which wallets are supported?</li>
              <p className="answer">-Phantom will be the only supported wallet.</p>
            </ul>
            <ul className="single-questions">
              <li className="faq">How can you be whitelisted?</li>
              <p className="answer">
                -Please check our #whitelist-condition channel on our Discord server.
                There is a detailed explanation of how you can earn a Whitelist spot.
              </p>
            </ul>
            <ul className="single-questions">
              <p className="answer">
                * FAQ section will be updated over time, until the mint date. 
              </p>
            </ul>
          </div>
        </div>
      </section>
      <section className="team" id="team">
        <div className="container">
          <h2><img src="./assets/images/core team 3.png" alt="hotdogz" /></h2>
          <div className="team-wrapper">
            <div className="team-member">
              <img
                src="./assets/images/team (4).png"
                alt="hotdogz"
                className="img-fluid"
              />
              <h4 className="name">
                <img src="./assets/images/team (2).png" alt="hotdogz" />
              </h4>
              <p className="position">Founder and Project Leader</p>
              <a target="__blank" href="https://twitter.com/Gr1nder4l1fe" className="social">
                <span><i className="fab fa-twitter"></i></span>
                <span>@waytoray411.eth.sol</span>
              </a>
            </div>
            <div className="team-member">
              <img
                src="./assets/images/team (5).png"
                alt=""
                className="img-fluid"
              />
              <h4 className="name">
                <img src="./assets/images/team (3).png" alt="hotdogz" />
              </h4>
              <p className="position">Founder and Design Leader</p>
              <a target="__blank" href="https://twitter.com/_disco_d_" className="social">
                <span><i className="fab fa-twitter"></i></span
                ><span> @disco_d</span>
              </a>
            </div>
            <div className="team-member">
              <img
                src="./assets/images/team (6).png"
                alt=""
                className="img-fluid"
              />
              <p className="position">Main Developer</p>
              <a target="__blank" href="https://twitter.com/jubaedprince" className="social">
                <span><i className="fab fa-twitter"></i></span> <span>@Prince</span>
              </a>
            </div>
            <div className="team-member">
              <img
                src="./assets/images/team (7).png"
                alt=""
                className="img-fluid"
              />
              <p className="position">Main Advisor</p>
              <a target="__blank" href="https://twitter.com/PetarNFT" className="social">
                <span><i className="fab fa-twitter"></i></span> <span>@Petar</span>
              </a>
            </div>
            <div className="team-member">
              <img
                src="./assets/images/team (8).png"
                alt=""
                className="img-fluid"
              />
              <p className="position">Chief Moderator</p>
              <a target="__blank" href="https://twitter.com/prob1a" className="social">
                <span><i className="fab fa-twitter"></i></span> <span>@robia</span>
              </a>
            </div>
            <div className="team-member">
              <img
                src="./assets/images/team (9).png"
                alt=""
                className="img-fluid"
              />
              <p className="position">Community Manager</p>
              <a target="__blank" href="https://twitter.com/MrJCHEETAH" className="social">
                <span><i className="fab fa-twitter"></i></span>
                <span>@mrJ </span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </main>


    <footer className="footer">
      <div className="container">
        <div className="flex-row">
          <div className="col-md-4">
            <p className="description">&copy; Copyright 2022 Solana Hot Dogz.</p>
          </div>
          <div className="col-md-4">
            <p className="description">Built on the Solana Blockchain.</p>
          </div>
          <div className="col-md-4">
            <ul className="icons-list">
              <li>
                <a target="_blank" href="https://discord.gg/Gh9GSdFPe7" rel="noreferrer">
                  <i className="fab fa-discord"></i>
                </a>
              </li>
              <li>
                <a target="_blank" href="https://twitter.com/HotDogzVerse" rel="noreferrer">
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a target="_blank" href="https://www.instagram.com/hotdogz_nft" rel="noreferrer">
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
    </div>
  );

  function navChange(){
    console.log("first run")
    const toggleBtn = document.querySelector('.toggle-menu')

    const mobileNav = document.querySelector('.nav-list')
    
      mobileNav.classList.toggle('active')
      toggleBtn.classList.toggle('active')
      if (toggleBtn.classList.contains('active')) {
        toggleBtn.innerHTML = `<i class="fa fa-times"></i>`
      } else {
        toggleBtn.innerHTML = `<i class="fa fa-bars"></i>`
      }
    
    console.log("last run")
  }
}


export default App;
